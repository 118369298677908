.person-marker {
  display: flex;
}

.person-marker-image {
  border-radius: 38px;
  height: 38px;
  width: 38px;
}

.person-marker-extra-ppl-div {
  border: 1px solid #d1d1d1;
  border-radius: 37px;
  height: 37px;
  width: 37px;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 37px;
  text-align: center;
}
