#bluedotIcon {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #1e90ff;
  border: 2px solid white;
  cursor: pointer;
  box-shadow: white;
}

#pulseIcon {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #1e90ff;
  border: 2px solid white;
  cursor: pointer;
  box-shadow: white;
  animation: pulse-bluedot 2s ease-out 2s infinite;
}

#pulseIcon:hover {
  animation: none;
}

#dynamicBluedotContainer {
  border-radius: 50%;
}

#dynamicBluedotDirection {
  height: 60px;
  width: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 40px solid rgba(30, 144, 255, 0.37);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: linear-gradient(180deg, rgba(30, 144, 255, 0.37) 50%, rgba(30, 144, 255, 0.04) 100%);
  -webkit-mask: conic-gradient(from -45deg at bottom, #0000, #000 1deg 90deg, #0000 91deg);
}

#dynamicBluedotIcon {
  border-radius: 50%;
  transform: translate(-11px, -15px);
  width: 20px;
  height: 20px;
  background: #1e90ff;
  animation: pulse-dynamic-bluedot 2s ease-out 2s infinite;
  border: 2px solid white;
  box-shadow: white;
}

@keyframes pulse-bluedot {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 204, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 30px rgba(0, 204, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 204, 255, 0);
  }
}

@keyframes pulse-dynamic-bluedot {
  0% {
    box-shadow: 0 0 0 0 rgba(30, 144, 255, 0.37);
  }
  70% {
    box-shadow: 0 0 0 30px rgba(0, 204, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 204, 255, 0);
  }
}

/*
animation: value;
/* -moz-animation: value;    /* Firefox */
/* -webkit-animation: value; /* Safari and Chrome */
/* -o-animation: value;    /* Opera */
/* -ms-animation: value;    /* IE */
