#marker-popup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 3px;
  background: #0078d4;
  box-shadow:
    0px 6.4px 14.4px rgba(0, 0, 0, 0.132),
    0px 1.2px 3.6px rgba(0, 0, 0, 0.108);
  border-radius: 4px;
  cursor: pointer;
}

#marker-popup > span {
  font-family: "Segoe UI";
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
  height: 20px;
}
