.popup-content-container {
  box-shadow: none;
}

.hotDesk-popup-container {
  position: relative;
  font-family: "Segoe UI";
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  flex-grow: 0;
  margin: 0px 0px;
  padding: 1px;
}

.small-popup {
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: 14px;
  row-gap: 4px;
}

.medium-popup {
  width: 35px;
  height: 44px;
  font-size: 10px;
  line-height: 8px;
}

.large-popup {
  width: 72px;
  font-size: 14px;
  line-height: 14px;
  row-gap: 4px;
}

.hotDesk-popup-container > :nth-child(1) {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px;
}

.hotDesk-popup-container > :nth-child(1) > span {
  text-align: center;
  font-size: 14px;
  display: inline-block;
  width: 100%;
  color: #ffffff;
  line-height: 14px;
  font-weight: normal;
  padding: 1.5px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.hotDesk-popup-container > :nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 28px;
  padding: 0px !important;
}

.hotDesk-popup-container > :nth-child(2) > span {
  color: #d6d6d6;
  line-height: 14px;
  word-wrap: break-word;
  padding: 3px;
  border: none;
  text-align: center;
  font-size: 14px;
  width: 57px;
  white-space: normal;
  width: 100%;
  padding-bottom: 1.5px;
}
