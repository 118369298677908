.person-layer-marker-shadow {
  -webkit-filter: drop-shadow(0px -3px 7px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0px -3px 7px rgba(0, 0, 0, 0.25));
}

.person-layer-marker {
  position: absolute;
  top: 14px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  font-size: 18px;
  font-weight: 600;
  font-family:
    "Segoe UI",
    "Segoe UI Web (West European)",
    "Segoe UI",
    -apple-system,
    "system-ui",
    Roboto,
    "Helvetica Neue",
    sans-serif;
}
