.popup-content-container {
  box-shadow: none;
}

.peopleCount-marker-container {
  position: relative;
  font-family: "Segoe UI";
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  flex-grow: 0;
  margin: 0px 0px;
  padding: 1px;
}

.peopleCount-small {
  width: 25px;
  height: 25px;
  font-size: 14px;
  line-height: 14px;
  row-gap: 4px;
}

.peopleCount-medium {
  width: 33px;
  height: 44px;
  font-size: 10px;
  line-height: 8px;
}

.peopleCount-large {
  width: 70px;
  font-size: 14px;
  height: 90px;
  line-height: 14px;
  row-gap: 4px;
}

.peopleCount-marker-container > :nth-child(1) {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px;
}

.peopleCount-marker-container > :nth-child(1) > span {
  text-align: center;
  font-size: 14px;
  display: inline-block;
  width: 100%;
  background-image: linear-gradient(to right, #4e8205, #4e8205 80%, #92c353);
  color: #171717;
  line-height: 14px;
  font-weight: normal;
  padding: 1.5px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.peopleCount-marker-container > :nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 28px;
  padding: 0px !important;
}

.peopleCount-marker-container > :nth-child(2) > span {
  color: #d6d6d6;
  line-height: 14px;
  word-wrap: break-word;
  padding: 3px;
  border: none;
  text-align: center;
  font-size: 14px;
  width: 57px;
  white-space: normal;
  width: 100%;
  padding-bottom: 1.5px;
}
